import React, { FC } from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import classNames from 'classnames';
import css from './Button.module.css';

export enum ButtonColor {
    WHITE,
    DARK,
    SUCCESS,
    ERROR,
    GRAY,
}

export interface ButtonProps {
    // Optional:
    color?: ButtonColor;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    type?: 'submit';
}

export const Button: FC<ButtonProps> = props => {
    const { color, className, ...rest } = props;
    const backgroundColorClassName = css['backgroundColor_' + (ButtonColor[color] || '')] || css.backgroundColor;
    const colorClassName = css['color_' + ButtonColor[color]] || css.color;
    const hoverClassName = css['hov_' + ButtonColor[color]] || css.hover;

    return (
        <MaterialButton
            className={classNames(
                css.containedPrimary,
                className,
                backgroundColorClassName,
                colorClassName,
                hoverClassName,
            )}
            variant="contained"
            disableElevation={true}
            {...rest}
        />
    );
};
