import React, { ChangeEvent, MouseEvent } from 'react';
import { RadioGroup, FormControlLabel, Popover } from '@material-ui/core';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { appConfig } from '../../config/app';
import { languageCodeOnly } from '../../core/utils/utils';
import classes from '../SelectRegionControl/style.module.css';
import i18nLib from '../../../i18n';

export const SelectLanguageControl = () => {
    const { i18n } = i18nLib.useTranslation();
    const handleLanguageChange = React.useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            i18n.changeLanguage(e.target.value, () => window.location.reload());
        },
        [i18n.changeLanguage],
    );
    const radioElements = appConfig.getLanguages;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div className={classes.button} aria-describedby={id} onClick={handleClick}>
                {languageCodeOnly(i18n.language).toUpperCase()}
                <div className={classes.triangle} />
            </div>
            <Popover className={classes.popover} id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
                <RadioGroup
                    className={classes.popover}
                    name={'language'}
                    style={{ width: '100%' }}
                    value={languageCodeOnly(i18n.language)}
                    onChange={handleLanguageChange}
                >
                    {radioElements.map(el => (
                        <FormControlLabel key={el.code} value={el.code} control={<RadioButton />} label={el.label} />
                    ))}
                </RadioGroup>
            </Popover>
        </>
    );
};
