import React, { FC } from 'react';
import { Button as MaterialButton, ThemeOptions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

export enum ButtonColor {
    WHITE,
    DARK,
    SUCCESS,
    ERROR,
    GRAY,
    GREEN,
}

export interface ButtonProps {
    // Optional:
    color?: ButtonColor;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    type?: 'submit';
}

const getColor = (theme: ThemeOptions, color: ButtonColor) => {
    switch (color) {
        case ButtonColor.WHITE:
            return theme.palette.fixedPrimary.primary;
        case ButtonColor.DARK:
            return theme.palette.fixedWhite.primary;
        case ButtonColor.SUCCESS:
            return theme.palette.fixedWhite.primary;
        case ButtonColor.ERROR:
            return theme.palette.fixedDanger.main;
        case ButtonColor.GRAY:
            return theme.palette.brandPrimary.main;
        default:
            return theme.palette.fixedWhite.primary;
    }
};

const getHoverColor = (theme: ThemeOptions, color: ButtonColor) => {
    switch (color) {
        case ButtonColor.WHITE:
        case ButtonColor.GRAY:
            return theme.palette.brandPrimary.accent;
        case ButtonColor.SUCCESS:
            return theme.palette.fixedSuccess.mainHover;
        case ButtonColor.ERROR:
            return theme.palette.fixedDanger.accent;
        default:
            return theme.palette.brandPrimary.mainHover;
    }
};

const getBackgroundColor = (theme: ThemeOptions, color: ButtonColor) => {
    switch (color) {
        case ButtonColor.WHITE:
            return theme.palette.fixedWhite.primary;
        case ButtonColor.DARK:
            return theme.palette.fixedPrimary.primary;
        case ButtonColor.SUCCESS:
            return theme.palette.fixedSuccess.main;
        case ButtonColor.ERROR:
            return theme.palette.fixedDanger.accent;
        case ButtonColor.GRAY:
            return theme.palette.brandPrimary.lightest;
        case ButtonColor.GREEN:
            return theme.palette.fixedArrowGreen.main;
        default:
            return theme.palette.brandPrimary.accent;
    }
};

const useStyles = makeStyles(theme => ({
    buttonBorder: {
        backgroundColor: (props: { color: ButtonColor }) => `${getBackgroundColor(theme, props.color)} !important`,
        color: (props: { color: ButtonColor }) => `${getColor(theme, props.color)} !important`,
        borderRadius: `${theme.spacing(3)}px !important`,
        fontWeight: '600 !important',
        textTransform: 'none !important',
        paddingLeft: `${theme.spacing(2)}px !important`,
        paddingRight: `${theme.spacing(2)}px !important`,
        paddingTop: `${theme.spacing(2.5)}px !important`,
        paddingBottom: `${theme.spacing(2.5)}px !important`,
        fontSize: '16px !important',
        lineHeight: '1.5 !important',
        height: '24px !important',
        whiteSpace: 'nowrap !important',
        '&:hover': {
            backgroundColor: (props: { color: ButtonColor }) => `${getHoverColor(theme, props.color)} !important`,
        },
    },
}));

export const ButtonBorder: FC<ButtonProps> = props => {
    const { color, className, ...rest } = props;
    const classes = useStyles({ color });

    return (
        <>
            <MaterialButton
                className={classNames(classes.buttonBorder, className)}
                variant="contained"
                disableElevation={true}
                {...rest}
            />
        </>
    );
};
